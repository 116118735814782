/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import WorkSheetList from "./WorkSheetList";
import DualRangeSlider from "../../../Templates/_Parts/DualRange";
import { filterData, SearchType } from "filter-data";
import {isMobile, isTablet, TabletView } from 'react-device-detect';
import {
  ChevronDownIcon,
  ChevronRightIcon,
  HeartIcon as HeartIconRound,
  DocumentTextIcon as DocumentTextSolid,
  MagnifyingGlassIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import {
  HeartIcon as HeartIconOutline,
  DocumentTextIcon as DocumentTextOutline,
} from "@heroicons/react/24/outline";
import "../_styles/FilterBar.css";


const FilterBar = forwardRef(
  (
    {
      showFavs,
      handleFavsClick,
      floorplans,
      favorites,
      worksheets,
      toggleWorksheets,
      setFloorplans,
      units,
      sketchStyle,
      masterSketchRef,
      sketchSetup,
      updateBuildBoxes,
      resetBoxes,
      isTablet,
      isLandscape
    },
    ref
  ) => {
    const [worksheetsList, setWorksheetsList] = useState(false);
    const [unitsAvailables, setUnitsAvailables] = useState([]);
    const [unitsSold, setUnitsSold] = useState([]);
    const [initFloorplans, setInitFloorplans] = useState([]);
    const [filteredFloorplans, setFilteredFloorplans] = useState([]);
    const [typeFilter, setUniq] = useState([]);
    const [oriFilter, setOriFilter] = useState("");
    const [bathFilter, setBathFilter] = useState("");
    const [filterType, setFilterType] = useState("");
    const [filterBath, setFilterBath] = useState([]);
    const [filterOrientation, setFilterOrientation] = useState([]);
    const [minArea, setMinArea] = useState(0);
    const [minSize, setMinSize] = useState(0);
    const [maxArea, setMaxArea] = useState(0);
    const [maxSize, setMaxSize] = useState(0);
    const [showFilters, setShowFilters] = useState(false);
    const [selectedNames, setSelectedNames] = useState("");
    const [rotations, setRotations] = useState({});
    const [filterBarVisible, setFilterBarVisible] = useState(true);
    const [isMasterSketchReady, setIsMasterSketchReady] = useState(false);

    const sliderRef = useRef();

    useEffect(() => {
      const checkInterval = setInterval(() => {
        if (
          masterSketchRef.current &&
          typeof masterSketchRef.current.displayModelAvailability === "function"
        ) {
          setIsMasterSketchReady(true);
          clearInterval(checkInterval);
        }
      }, 100);
      return () => clearInterval(checkInterval);
    }, [masterSketchRef, resetBoxes, isMasterSketchReady]);

    useEffect(() => {
      if (floorplans.length > 0) {
        const searchConditions = [
          {
            key: "fp_name",
            value: selectedNames,
            type: selectedNames !== "" ? SearchType.LK : SearchType.NE,
          },
          {
            key: "fp_bedrooms",
            value: filterType,
            type: filterType !== "" ? SearchType.LK : SearchType.NE,
          },
          {
            key: "fp_size",
            value: minArea,
            type: SearchType.GT,
          },
          {
            key: "fp_size",
            value: maxArea,
            type: SearchType.LT,
          },
          {
            key: "fp_bathrooms",
            value: bathFilter,
            type: bathFilter !== "" ? SearchType.LK : SearchType.NE,
          },
          {
            key: "fp_orientations.fp_orientation",
            value: oriFilter,
            type: oriFilter !== "" ? SearchType.EQ : SearchType.NLK,
          },
        ];

        const filtered = filterData(initFloorplans, searchConditions);

        if (filtered.length > 0) {
          setFilteredFloorplans(filtered);
          setFloorplans(filtered);
          refreshBuildBoxes(filtered);
        } else {
          setFilteredFloorplans([]);
          setFloorplans([]);
          refreshBuildBoxes([]);
        }
      }
    }, [
      filterType,
      filterBath,
      minArea,
      maxArea,
      initFloorplans,
      oriFilter,
      bathFilter,
      selectedNames,
    ]);

    useEffect(() => {}, [
      filterType,
      minArea,
      maxArea,
      oriFilter,
      filteredFloorplans,
    ]);

    const toggleFilterBar = () => {
      setFilterBarVisible(!filterBarVisible);
    };

    const handleNameChange = (e) => {
      const options = e.target.options;
      const selectedValues = Array.from(options)
        .filter((option) => option.selected)
        .map((option) => option.value);

      setSelectedNames(selectedValues);
    };

    const handleRotateClick = (itemName) => {
      setRotations((prevRotations) => ({
        ...prevRotations,
        [itemName]: !prevRotations[itemName],
      }));
    };

    const handleBedroomChange = async (e) => {
      const value = e.target.value;
      setFilterType(value);
    };

    const handleRangeChange = ({ min, max }) => {
      setMinArea(min);
      setMaxArea(max);
    };

    const handleReset = () => {
      if (sliderRef.current) sliderRef.current.resetSlider();

      setMinArea(minSize);
      setMinSize(minSize);
      setMaxArea(maxSize);
      setMaxSize(maxSize);
      setFilterType("");
      setOriFilter("");
      setBathFilter("");
      setSelectedNames("");
      setFilterBath("");
      setFloorplans(initFloorplans);
      setFilteredFloorplans(initFloorplans);
      resetBoxes();
      masterSketchRef.current.handleZoomOut();
    };

    const refreshBuildBoxes = async (filter) => {
      if (filter.length === 0) {
        setShowFilters(false);
        if (isMasterSketchReady) {
          updateBuildBoxes([], [], sketchStyle);
        }
        return;
      }

      let filteredUnitsAvailable = units
        .filter((unit) =>
          filter.some(
            (fp) =>
              fp.fp_id === unit.fp_id &&
              unit.unit_status === "available" &&
              (!oriFilter || unit.unit_orientation === oriFilter)
          )
        )
        .map((unit) => unit.unit_model_name);
      setUnitsAvailables(filteredUnitsAvailable);

      let filteredUnitsSold = units
        .filter((unit) =>
          filter.some(
            (fp) =>
              fp.fp_id === unit.fp_id &&
              unit.unit_status !== "available" &&
              (!oriFilter || unit.unit_orientation === oriFilter)
          )
        )
        .map((unit) => unit.unit_model_name);
      setUnitsSold(filteredUnitsSold);

      await updateBuildBoxes(
        filteredUnitsAvailable,
        filteredUnitsSold,
        sketchStyle
      );

      let orientationCam = oriFilter
        ? oriFilter
        : units.find((unit) => unit.fp_id === filter[0].fp_id).unit_orientation;
      if (isMasterSketchReady) {
        await masterSketchRef.current.sketchRotateCamera(
          sketchSetup.building_name,
          sketchSetup.orientation_camera[sketchSetup.building_name].orientation[
            orientationCam
          ]
        );
      }
    };

    useEffect(() => {
      const handleClick = async (event) => {
        const closeButton = event.target.closest("#close-floorplan");
        if (closeButton && filteredFloorplans.length < initFloorplans.length) {
          await updateBuildBoxes(unitsAvailables, unitsSold, sketchStyle);
        }
      };

      document.addEventListener("click", handleClick);

      return () => {
        document.removeEventListener("click", handleClick);
      };
    }, [unitsAvailables, unitsSold, sketchStyle]);

    useEffect(() => {
      if (floorplans.length > 0 && initFloorplans.length === 0) {
        let uniq = [...new Set(floorplans.map((fp) => fp.fp_bedrooms))];
        let baths = [...new Set(floorplans.map((fp) => fp.fp_bathrooms))];
        let orientation = [
          ...new Set(
            floorplans.map((fp) => fp.fp_orientations.fp_orientation).flat()
          ),
        ];
        setFilterOrientation(orientation);
        setFilterBath(baths);
        setInitFloorplans(floorplans);
        setUniq(uniq);

        if (floorplans.length > 0) {
          const maxFloorplanSize = Math.max(
            ...floorplans.map((fp) => fp.fp_size)
          );

          const minFloorplanSize = Math.min(
            ...floorplans.map((fp) => fp.fp_size)
          );

          setMaxSize(maxFloorplanSize + 20);
          setMaxArea(maxFloorplanSize + 20);
          setMinArea(minFloorplanSize - 10);
          setMinSize(minFloorplanSize - 10);
        }
      }
    }, [floorplans, initFloorplans]);

    useImperativeHandle(ref, () => ({
      handleReset,
      oriFilter
    }));

    return (
      <div
        className={`filter-container items-center pb-4 flex fixed z-10 right-5 ${
          filterBarVisible ? "justify-between" : "justify-end"
        }`}
      >
       {isLandscape && (
        <div
          className={`relative filter-bar ${
            filterBarVisible ? "show" : "hide"
          }`}
          style={{
            width: filterBarVisible ? "790px" : "50px", 
            opacity: filterBarVisible ? 1 : 0,
            transform: filterBarVisible ? "translateX(0)" : "translateX(-100%)",
            transition: "all 0.2s ease", 
          }}
        >
          <div className="item" onClick={() => handleRotateClick("byTape")}>
            <select onChange={handleBedroomChange} value={filterType}>
              <option value="">TYPE</option>
              {typeFilter &&
                typeFilter.map((bedroom) => (
                  <option key={bedroom} value={bedroom}>
                    {bedroom} BED
                  </option>
                ))}
            </select>
            <ChevronDownIcon
              className="size-6"
              style={{
                transform: rotations["byTape"]
                  ? "rotate(180deg)"
                  : "rotate(0deg)",
                transition: "transform 0.2s ease",
              }}
            />
          </div>

          <div
            className="relative more-filters-toggle item"
            onClick={() => {
              setShowFilters(!showFilters);
              handleRotateClick("byOthers");
            }}
            style={{ cursor: "pointer" }}
          >
            MORE FILTERS
            <ChevronDownIcon
              className="size-6"
              style={{
                transform: rotations["byOthers"]
                  ? "rotate(180deg)"
                  : "rotate(0deg)",
                transition: "transform 0.2s ease",
              }}
            />
            <div
              className={`absolute others-filters ${
                showFilters ? "show" : "hide"
              }`}
              style={{ display: showFilters ? "block" : "none" }}
              onClick={(e) => e.stopPropagation()}
            >
              <XMarkIcon
                className="close-filters"
                onClick={() => setShowFilters(false)}
              />
              <div className="area-filter">
                <div className="area-title mb-5">AREA (SQ.FT.)</div>
                <DualRangeSlider
                  ref={sliderRef}
                  min={minSize}
                  max={maxSize}
                  onMouseUp={({ min, max }) => {
                    handleRangeChange({ min, max });
                  }}
                  minVal={minArea}
                  maxVal={maxArea}
                />
              </div>

              <div className="area-filter">
                <div className="area-title">BATHROOMS</div>
                <select
                  onChange={(e) => setBathFilter(e.target.value)}
                  value={bathFilter}
                >
                  <option value="">ALL</option>
                  {filterBath &&
                    filterBath.map((bath) => (
                      <option key={bath} value={bath}>
                        {bath} BATH
                      </option>
                    ))}
                </select>
              </div>

              <div className="area-filter">
                <div className="area-title">ORIENTATION</div>
                <select
                  onChange={(e) => setOriFilter(e.target.value)}
                  value={oriFilter}
                >
                  <option value="">ALL</option>
                  {filterOrientation &&
                    filterOrientation.map((orientation) => (
                      <option key={orientation} value={orientation}>
                        {orientation}
                      </option>
                    ))}
                </select>
              </div>
              <button
                className="btn"
                onClick={() => {
                  handleReset();
                  setShowFilters(!showFilters);
                }}
              >
                RESET 
              </button>
            </div>
          </div>

          <div
            className="area-filter item"
            onClick={() => handleRotateClick("byName")}
          >
            <select onChange={handleNameChange} value={selectedNames}>
              <option value="">BY NAME</option>
              {initFloorplans &&
                initFloorplans.map((fp) => (
                  <option key={fp.fp_name} value={fp.fp_name}>
                    {fp.fp_name}
                  </option>
                ))}
            </select>
            <ChevronDownIcon
              className="size-6"
              style={{
                transform: rotations["byName"]
                  ? "rotate(180deg)"
                  : "rotate(0deg)",
                transition: "transform 0.2s ease",
              }}
            />
          </div>

          <div className="item">
            <button
              className="btn"
              onClick={() => {
                handleReset();
              }}
            >
              RESET 
            </button>
            <div
              className="close-filter relative"
              onClick={toggleFilterBar}
              style={{ display: toggleFilterBar ? "block" : "none" }}
            >
              <ChevronRightIcon className="size-6" />
            </div>
          </div>
        </div>
      )}
        {!filterBarVisible && (
          <div
            className="top-icon-menu open-filter"
            style={{
              opacity: filterBarVisible ? 0 : 1,
              transition: "opacity 0.3s ease",
            }}
            onClick={toggleFilterBar}
          >
            <MagnifyingGlassIcon className="size-6" />
          </div>
        )}

        <div
          className={`worksheet-count top-icon-menu`}
          onClick={() =>
            worksheets.length > 0 && !worksheetsList
              ? setWorksheetsList(true)
              : null
          }
        >
          <div className="qty-worksheet">{worksheets.length}</div>
          {worksheets.length === 0 ? (
            <DocumentTextOutline className="size-6" />
          ) : (
            <DocumentTextSolid className="size-6" />
          )}
          {worksheetsList && (
            <WorkSheetList
              worksheets={worksheets}
              setWorksheetsList={setWorksheetsList}
              toggleWorksheets={toggleWorksheets}
            />
          )}
        </div>

        <div
          className={`favs-count top-icon-menu ${showFavs ? "active" : ""}`}
          onClick={favorites.length > 0 ? handleFavsClick : null}
        >
          <div className={`qty-favs ${showFavs ? "hide" : "show"}`}>
            {favorites.length}
          </div>
          {showFavs ? (
            <XMarkIcon className="size-6" />
          ) : (
            <>
              {favorites.length === 0 ? (
                <HeartIconOutline className="size-6" />
              ) : (
                <HeartIconRound className="size-6" />
              )}
            </>
          )}
        </div>
      </div>
    );
  }
);

export default FilterBar;
