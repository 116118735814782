/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
import React, { useCallback, useEffect, useState, useRef } from "react";
import DetailsFloorplan from "./DetailsFloorplan";
import { HeartIcon as HeartIconOutline } from "@heroicons/react/24/outline";
import { HeartIcon as HeartIconSolid } from "@heroicons/react/24/solid";
import _ from "lodash";
import PanoramaView from "./PanoramaView";
import RequestInfoForm from "./RequestInfoForm";
import {isMobile } from 'react-device-detect';

import '../_styles/SelectedFloorplanSlide.css';

const SelectedFloorplanSlide = ({
  floorplan,
  onClose,
  unitRender,
  setSelectedUnitRender,
  isFavorite,
  floorUnit,
  pointForUnit,
  getFloorplate,
  setPointUnit,
  styleProject,
  toggleFavorite,
  units,
  masterSketchRef,
  views,
  activeComparation,
  filterBarRef,
  contactEmail,
}) => {
  const [showRequestModal, setShowRequestModal] = useState(false);
  const [othersUnits, setOthersUnits] = useState([]);
  const [unitOri, setUnitOri] = useState("");
  const [selectedView, setSelectedView] = useState(null);
  const [showPanorama, setShowPanorama] = useState(false);
  const [isComparation, setIsComparation] = useState(false);

  const openModal = () => setShowRequestModal(true);
  const closeModal = () => setShowRequestModal(false);

  /**
   * Filters and sorts units based on the given floorplan ID and their availability status.
   *
   * @param {number} floorplanId - The ID of the floorplan to filter units by.
   * @returns {void} This function does not return a value. It sets the state with the filtered and sorted units.
   */
  const getOthersUnits = (floorplanId) => {
    let othersUnits = units.filter((unit) => 
      unit.fp_id === floorplanId && 
      unit.unit_status === "available" && 
      (filterBarRef.current.oriFilter !== "" 
          ? unit.unit_orientation === filterBarRef.current.oriFilter 
          : true)
  );
  

    let orderUnits = othersUnits.sort((a, b) =>
      parseInt(a.unit_name) > parseInt(b.unit_name) ? 1 : -1
    );
    setOthersUnits(orderUnits);
  };

  /**
   * Handles the change of the selected unit.
   *
   * This function is memoized using `useCallback` to prevent unnecessary re-renders.
   * It updates the selected unit render and orientation based on the provided unit data.
   *
   * @param {Object} unit - The unit object containing details about the selected unit.
   * @param {string} unit.unit_model_name - The model name of the unit.
   * @param {string} unit.unit_number - The number of the unit.
   * @param {string} unit.unit_orientation - The orientation of the unit.
   * @returns {Promise<void>} - A promise that resolves when the unit change handling is complete.
   */
  const handleUnitChange = useCallback(
    async (unit) => {
      if (masterSketchRef.current) {
        const unitObj = JSON.parse(unit);
        await masterSketchRef.current.filterByFloorplanId(
          floorplan.fp_id,
          unitObj.unit_model_name
        );

        const point = await getFloorplate(unitObj.unit_id);

        setSelectedUnitRender(unitObj.unit_number);
        setUnitOri(unit.unit_orientation);
        setPointUnit(point);
      }
    },
    [masterSketchRef, floorplan.fp_id]
  );

  /**
   * Sets the selected view based on the current floor unit and displays the panorama.
   *
   * This function searches through the available views to find one that includes the specified floor unit.
   * Once found, it sets the selected view and enables the panorama display.
   *
   * @function
   */
  const setView = () => {
    const view = views.find((view) => view.floors.includes(floorUnit));
    setSelectedView(view);
    setShowPanorama(true);
  };

  useEffect(() => {
    getOthersUnits(floorplan.fp_id);
  }, [floorplan, unitOri, pointForUnit]);

  return (
    <div className="selected-floorplan-slide"
    style={{boxShadow: isComparation ? '-9px 0px 13px -3px rgb(233 233 233 / 75%)' : ''}}>
      <button className="close-btn" id="close-floorplan" onClick={onClose}>
        &times;
      </button>
      <img
        src={floorplan.fp_image_med[0]}
        alt={floorplan.fp_name}
        className="full-size-image"
      />

      <div className="pb-5 w-90px">
        <div className="grid grid-cols-4 gap-4 item-center container-data">
          <div className="py-4 flex-1 floorplate-img relative">
            {pointForUnit && pointForUnit.point && (
              <>
                <div
                  className="unit-float"
                  style={{
                    top: `${pointForUnit.point.point_position_y}%`,
                    left: `${pointForUnit.point.point_position_x}%`,
                    border: `none`,
                    background: `${
                      styleProject["noUi-connect"]["background-color"] !==
                      "#000000"
                        ? styleProject["noUi-connect"]["background-color"]
                        : styleProject.titleCardsColors["color"]
                    }`,
                  }}
                >
                  {unitRender}
                </div>
                <img src={pointForUnit.floorplate_image} width="100%" />
              </>
            )}
          </div>

          <div className="info-floor col-span-2">
            <div
              className="fav-button flex items-center"
              onClick={(event) => {
                event.stopPropagation();
                toggleFavorite();
              }}
            >
              {!isFavorite ? (
                <HeartIconOutline
                  style={{
                    color: `${
                      styleProject["noUi-connect"]["background-color"] !==
                      "#000000"
                        ? styleProject["noUi-connect"]["background-color"]
                        : styleProject.titleCardsColors["color"]
                    }`,
                  }}
                />
              ) : (
                <HeartIconSolid
                  style={{
                    color: `${
                      styleProject["noUi-connect"]["background-color"] !==
                      "#000000"
                        ? styleProject["noUi-connect"]["background-color"]
                        : styleProject.titleCardsColors["color"]
                    }`,
                  }}
                />
              )}
              <h2 className="text-xl title-big-floorplan ml-1">
                {floorplan.fp_name.toUpperCase()}
              </h2>
            </div>

            <div className="flex items-center">
              <small>UNIT </small>
              <div className="text-lg ml-1">{unitRender}</div>
            </div>
            <DetailsFloorplan
              floorplan={floorplan}
              floorUnit={floorUnit}
              unitOri={unitOri}
            />
            <div className="mt-4 flex buttons-group">
              <a
                className="btn btn-primary square-button"
                href={floorplan.fp_pdf}
                target="_blank"
              >
                Download Floorplan
              </a>
              <button
                className="btn btn-primary square-button black ml-2"
                data-modal-target="request-info"
                data-modal-toggle="request-info"
                onClick={openModal}
              >
                Request Information
              </button>

              <select
                className="ml-2 form-select-floorplan"
                style={{ display: isComparation ? "none" : "block" }}
                onChange={(e) => handleUnitChange(e.target.value)}
              >
                <option value="Select Unit">Other Units</option>
                {othersUnits &&
                  othersUnits.sort().map(
                    (unit) =>
                      unit.unit_number !== unitRender && (
                        <option key={unit.unit_id} value={JSON.stringify(unit)}>
                          {unit.unit_number}
                        </option>
                      )
                  )}
                ;
              </select>
              {!isComparation && (
              <button
                className="btn btn-primary square-button ml-2 w-auto items-center justify-center"
                onClick={() => setView()}
              >
                VIEWS
              </button>
               )}
            {!isMobile && (
              <button
                className="btn btn-primary square-button ml-2 w-auto items-center justify-center"
                style={{ display: isComparation ? "none" : "block" }}
                onClick={() => {
                  activeComparation();
                  setIsComparation(true);
                }}
              >
                COMPARE
              </button>
         )}
            </div>
          </div>
        </div>
      </div>
      {selectedView && showPanorama && (
        <PanoramaView
          data={selectedView}
          onClosePanorama={() => setShowPanorama(false)}
        />
      )}
     {showRequestModal && (
        <RequestInfoForm closeModal={closeModal} floorplan={floorplan} contactEmail={contactEmail} unitFloor={floorUnit}/>
      )}
    </div>
  );
};

export default SelectedFloorplanSlide;
